import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from "gatsby-plugin-image"
import { FaChevronRight } from "react-icons/fa"

import addQuery from '../../utils/addQuery'
import ContentContainer from '../UI/ContentContainer'
import { BUTTON_COLOR, BUTTON_SIZE } from '../../support'
import HomeButton from '../UI/Buttons/HomeButton'
import CMSImage from '../UI/CMSImage'

const HeaderWithImageWithBackground = ({page}) => {

	return (
		<div className="new-ui-hero">
		<ContentContainer background={`linear-gradient(70deg, ${page.headerBackgroundColorStart}, ${page.headerBackgroundColorEnd})`}>
			<Main>
				<MainGetRate>
					<div dangerouslySetInnerHTML={{
						__html: page.header?.childMarkdownRemark.html,
					}}/>
					<div className="subHeader" dangerouslySetInnerHTML={{
						__html: page.subheader?.childMarkdownRemark.html,
					}}/>
					<div className="btnWrapper">
						<HomeButton
							height={BUTTON_SIZE.HEIGHT_L}
							padding={BUTTON_SIZE.PADDING_L}
							fontSize={BUTTON_SIZE.FONT_SIZE_L}
							background={BUTTON_COLOR.WHITE}
							backgroundHover={BUTTON_COLOR.WHITE_HOVER}
							color={BUTTON_COLOR.TEXT_COLOR_BLUE}
							text={page.headerButton.text}
							handleClick={() => addQuery(page.headerButton.url)}
							margin='0'
						/>
					</div>
					<QuestionsWrapper>
						{page.headerQuestion && page.headerQuestion.map((item, index) =>
							<Question href={item.url} key={index}> 
								{
									item.iconOnLeft &&
									<div className="icon-wrapper">
										<CMSImage
											gatsbyImageData={item.iconOnLeft?.gatsbyImageData}
											file={item.iconOnLeft.file}
											alt={item.iconOnLeft.title}
										/>
									</div>
								}
								<p>{item.text}</p>
								<div className="right-arrow-icon">
									<FaChevronRight style={{ width: 10, height: 16 }} />
								</div>
							</Question>
						)}
					</QuestionsWrapper>
				</MainGetRate>
				<MainImg imgSize={page.page}>
					<GatsbyImage image={page.headerImage?.gatsbyImageData} alt={page.headerImage.title} />
				</MainImg>
			</Main>
		</ContentContainer>
		</div>
	)
}

const QuestionsWrapper = styled.div`
	color: white;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	@media screen and (max-width: 991px) {
		flex-direction: column;
		align-items: start;
	}
	@media screen and (max-width: 768px) {
		flex-direction: row;
		justify-content: space-evenly;
	}
`
const Main = styled.div`
	display: flex;	
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	${'' /* background: ${props => `linear-gradient(70deg, blue, pink)`}; */}
	@media screen and (max-width: 768px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	};

`
const MainGetRate = styled.div`
	max-width: 586px;
	@media screen and (max-width: 768px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	};
	h1 {
		margin: 10px 0;
		@media screen and (max-width: 991px) {
			font-size: 42px;
		}
		@media screen and (max-width: 768px) {
			text-align: center;
		};

	};
	a:hover {
		color: inherit;
	}
	.subHeader {
		p {
			font-size: 20px;
			@media screen and (max-width: 768px) {
				text-align: center;
			};
		}
	};
	.btnWrapper {
		padding: 40px 0;
		button {
			font-family: PoppinsBold;
			margin: 0;
			box-shadow: 0 6px 0 0 #94cfc5;
			transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
			transform-style: preserve-3d;
			transition: background-color .2s;
			&:hover {
				transform: translate3d(0px, 0px, 0px) scale3d(0.98, 0.98, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) !important;
				transform-style: preserve-3d !important;
			}
		}
		@media screen and (max-width: 768px) {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			button {
			margin: 0 auto;
			}

		}

	}
`
const Question = styled.a`
	display: flex;
	align-items: center;
	margin-right: 20px;
	p {
		margin: 5px 0;
	};
	.right-arrow-icon {
		display: flex;
		align-items: center;
		flex-shrink: 0;
		padding-left: 10px; 
		display: flex; 
		align-items: center;
	};
	.icon-wrapper {
		display: flex;
		align-items: center;
		flex-shrink: 0;
		width: 20px;
		height: auto;
		margin-right: 12px;
		svg {
			width: 100%;
			height: 100%;
		}
	}
	@media screen and (max-width: 768px) {
		justify-content: center;
		.icon{
			padding: 0;
		}
	}
	@media screen and (max-width: 505px) {
		margin-right: 5px;
		p {
			font-size: 3.5vw;
		}
		.icon-wrapper {
			margin-right: 5px;
		}
	}
`
const MainImg = styled.div`
	width: ${props => props.imgSize === 'personal-loans-a' ? '600px' : '445px'};
	img {
		padding: 0 !important;
	};

	@media screen and (max-width: 991px) {
		max-width: 396px;
	};
	@media screen and (max-width: 480px) {
		max-width: 100%;
	};

	img {
		padding-right: 50px;
	}
`

export default HeaderWithImageWithBackground
